// import React, { useState, useEffect, useRef } from 'react';
// import { ResourceRequester } from 'sulu-admin-bundle/services';
// import MultiMediaSelection from 'sulu-media-bundle/containers/MultiMediaSelection';
//
// const InfiniteScrollMediaSelection = ({ onChange, value, locale, ...rest }) => {
//     const [mediaItems, setMediaItems] = useState(value || []);
//     const [loadedItems, setLoadedItems] = useState([]);
//     const [page, setPage] = useState(1);
//     const [isLoading, setIsLoading] = useState(false);
//     const scrollContainerRef = useRef(null);
//
//     const loadMedia = () => {
//         if (isLoading) return;
//         setIsLoading(true);
//
//         ResourceRequester.get('media', {
//             page,
//             limit: 20,
//             locale: locale.get(), // Pobierz ustawienia językowe
//         }).then((response) => {
//             setLoadedItems((prevItems) => [...prevItems, ...response._embedded.media]);
//             setPage((prevPage) => prevPage + 1);
//             setIsLoading(false);
//         });
//     };
//
//     const handleScroll = () => {
//         if (!scrollContainerRef.current) return;
//
//         const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
//         if (scrollTop + clientHeight >= scrollHeight - 50) {
//             loadMedia();
//         }
//     };
//
//     useEffect(() => {
//         loadMedia(); // Initial load
//     }, []);
//
//     useEffect(() => {
//         const container = scrollContainerRef.current;
//         if (container) {
//             container.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (container) {
//                 container.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [scrollContainerRef, isLoading]);
//
//     const handleSelectionChange = (newSelectedItems) => {
//         setMediaItems(newSelectedItems);
//         onChange(newSelectedItems);
//     };
//
//     return (
//         <div ref={scrollContainerRef} style={{ height: '400px', overflowY: 'auto' }}>
//             <MultiMediaSelection
//                 {...rest}
//                 value={mediaItems}
//                 onChange={handleSelectionChange}
//                 items={loadedItems}
//             />
//             {isLoading && <div>Loading...</div>}
//         </div>
//     );
// };
//
// export default InfiniteScrollMediaSelection;
