var isSlider = document.getElementsByClassName('slider-mobi');
var isLogo = document.getElementsByClassName('logo-mobi');
var isChangeColor = document.getElementById('change-color');

if (isSlider.length === 0) {
    var el = document.getElementById('header-fixed');
    el.classList.add('link-black');
}

if (window.location.hostname === 'mh-trailers.com') {
    window.onscroll = function() {
        var className = 'scrolled';
        var el = document.getElementById('header-fixed');

        var pathLogoBlack = "/images/mobi/logo-mh.png";

        if (document.documentElement.lang === 'si') {
            pathLogoBlack = "/images/mobi/logo_black_si.png";
        } else if(document.documentElement.lang === 'pl') {
            pathLogoBlack = "/images/mobi/logo-mh.png";
        } else if(document.documentElement.lang === 'de') {
            pathLogoBlack = "/images/mobi/logo_black_de.png";
        } else if(document.documentElement.lang === 'en') {
            pathLogoBlack = "/images/mobi/logo_black.png";
        }
        if (el.classList) {
            if (window.scrollY > 100) {
                el.classList.add(className);
                document.getElementsByClassName("logo-mobi")[0].src = pathLogoBlack
            }
            else {
                el.classList.remove(className);
                document.getElementsByClassName("logo-mobi")[0].src = "/images/mobi/logo-mh.png"
            }
        } else {
            // IE Fix
            if (window.scrollY > 100)
                el.className += className;
            else
                el.className = str.replace(new RegExp("\\b"+className+"\\b","gi"),"");
        }
    }
} else {
    window.onscroll = function() {
        var className = 'scrolled';
        var el = document.getElementById('header-fixed');

        var pathLogoBlack = "/images/mobi/logo_black.png";

        if (document.documentElement.lang === 'si') {
            pathLogoBlack = "/images/mobi/logo_black_si.png";
        } else if(document.documentElement.lang === 'pl') {
            pathLogoBlack = "/images/mobi/logo_black.png";
        } else if(document.documentElement.lang === 'de') {
            pathLogoBlack = "/images/mobi/logo_black_de.png";
        } else if(document.documentElement.lang === 'en') {
            pathLogoBlack = "/images/mobi/logo_black.png";
        }
        if (el.classList) {
            if (window.scrollY > 100) {
                el.classList.add(className);
                document.getElementsByClassName("logo-mobi")[0].src = pathLogoBlack
            }
            else {
                el.classList.remove(className);
                document.getElementsByClassName("logo-mobi")[0].src = "/images/mobi/logo_white.png"
            }
        } else {
            // IE Fix
            if (window.scrollY > 100)
                el.className += className;
            else
                el.className = str.replace(new RegExp("\\b"+className+"\\b","gi"),"");
        }
    }
}


document.addEventListener("DOMContentLoaded", function(event) {
    var elements = document.getElementsByClassName('dropdownMobi');

    for (let i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (window.innerWidth < 1400) {
            element.setAttribute('data-bs-toggle', 'dropdown');
        } else {
            element.setAttribute('data-bs-toggle', '');
        }
    }
});