/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './js/app.js';
import './js/fslightbox.js';

import * as web from '@sulu/web';
import AjaxLink from './components/AjaxLink';
// import Partial from './components/partial';
import * as bootstrap from './components/bootstrap/dist/js/bootstrap.bundle'

import InfiniteScrollMediaSelection from './admin/fields/InfiniteScrollMediaSelection';
window.Sulu.fieldRegistry.add('infinite_scroll_media_selection', InfiniteScrollMediaSelection);

window.bootstrap = bootstrap;

web.registerComponent('ajax-link', AjaxLink);
// web.registerComponent('parial', Partial);


window.web = web;
// import 'bootstrap';
// import feather from "feather-icons";
// feather.replace();

